<template>
  <div style="padding: 20px">
    <div class="handle-box">
      <select-area @areaListId="getAreaListId"/>
      <el-select :size="size" v-model="query.status" placeholder="状态" class="handle-select mr10">
        <el-option key="0" label="全部" value=""></el-option>
        <el-option key="2" label="待审核" value="1"></el-option>
        <el-option key="2" label="同意" value="2"></el-option>
        <el-option key="3" label="拒绝" value="3"></el-option>
      </el-select>
      <el-select :size="size" v-model="query.type" placeholder="类型" class="handle-select mr10">
        <el-option key="1" label="订单退费" :value="1"></el-option>
        <el-option key="2" label="器材退费" :value="2"></el-option>
        <el-option key="4" label="招商退费" :value="4"></el-option>
      </el-select>
      <el-input :size="size" v-model="query.coachName" placeholder="教练姓名" class="handle-input mr10"/>
      <el-input :size="size" v-model="query.studentName" placeholder="学员姓名" class="handle-input mr10"/>
      <el-date-picker
          :size="size"
          class="handle-input mr10"
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
         >
      </el-date-picker>
      <el-date-picker
          :size="size"
          style="margin-left: 10px"
          class="handle-input mr10"
          v-model="completeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="完成开始日期"
          end-placeholder="完成结束日期"
         >
      </el-date-picker>
      <el-button :size="size" icon="Search" type="primary" @click="getData" style="margin-left: 20px">搜索</el-button>

      <el-button :size="size" type="info" @click="reset">重置</el-button>
      <el-popconfirm
          title="确定导出吗？" @confirm="exportExcel" >
        <template #reference>
          <el-button :size="size" type="primary">导出</el-button>
        </template>
      </el-popconfirm>
      <el-button :size="size" @click="importDialog = true" type="primary" >导入</el-button>
    </div>
    <el-table
        :data="data"
        border
        height="610px"
        v-loading="tableLoading"
        class="table el-table__cell"
        ref="multipleTable"
        :row-style="{height: '0'}"
        :cell-style="{padding: '0'}"
        header-cell-class-name="table-header"
        style="text-align: center"
    >
      <el-table-column prop="coachName" width="100" label="申请人"></el-table-column>
      <el-table-column width="100" label="订单类型">
        <template v-slot="{row}">
          <el-tag  type="danger">
              {{ row.type == 1 ? '课程退费' : row.type == 2 ? '器材退费' : row.type == 4 ? '招商退费' : '定金退费' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" width="100" label="退费金额">
        <template v-slot="{row}">
          <span v-if="row.money!==null">{{ row.money }} 元</span>
          <span v-if="row.money==null" style="color:#C0C4CC">暂无信息</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="100">
        <template v-slot="{row}">
          <el-popover
              placement="top"
              :width="row.auditorList.length * 250"
              trigger="hover"
          >
            <el-steps :space="200" :active="0" finish-status="success" align-center>
              <el-step v-for="(item,index) in row.auditorList" :key="index" :status="item.auditResult==2?'success':(item.auditResult==1?'wait':item.auditResult==3?'error':'wait')"
                       :title="item.coachName" :description="item.auditorTime" class="step" style="min-width: 230px;">
                       <template #description>
                          {{item.auditorTime}}
                          <div v-if="item.auditResult==3" style="min-width:230px;text-align:left;">
                           拒绝原因:  {{ item.remark == null ? '' : item.remark}}
                          </div>
                       </template>
              </el-step>
            </el-steps>
            <template #reference>
              <el-tag size="large"
                  :type="row.status == 1 ? 'info':(row.status == 2?'':(row.status == 3?'danger':'warning')) ">
                {{row.status == 1 ? '待审核' : (row.status == 2 ? '已通过' : (row.status == 3 ? '未通过' : '已撤销'))}}
              </el-tag>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="200" show-overflow-tooltip="true" label="所属城市-校区">
        <template #default="scope">{{ scope.row.regionName}}-{{ scope.row.campusName }}</template>
      </el-table-column>
      <el-table-column
          label="图片"
          width="90">
        <template v-slot="products">
          <el-image class="code"
                    hide-on-click-modal
                    :src="products.row.picture[0]"
                    :preview-src-list="products.row.picture"
          >
            <template #error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="assits"
          label="分成人"
          width="110">
        <template v-slot="{row}">
          <el-popover
              v-if="row.divide !==null && JSON.parse(row.divide)[0].name"
              placement="top"
              :width="300"
              trigger="hover">
            <div v-for="item in JSON.parse(row.divide)" :key="item.id">
              <div v-if="item.name" style="margin: 6px 0;display: flex;">
               <span style="width:50%">姓名：{{ item.name }}</span> 
                <el-divider direction="vertical"></el-divider>
                <span style="width:50%">  金额：{{ item.money }} </span>
              </div>
            </div>
            <template #reference>
              <el-tag size="large" style="width: 80px">查看信息</el-tag>
            </template>
          </el-popover>
          <span style="color:#C0C4CC" v-else>暂无信息</span>
        </template>
      </el-table-column>
      <el-table-column
          label="提交时间"
          width="180">
        <template v-slot="{row}">
          <span>{{ row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="完成时间"
          width="180">
        <template v-slot="{row}">
          <span>{{ row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          label="备注">
        <template v-slot="{row}">
          <span :style="row.remark==='' ? 'color:#C0C4CC': ''">{{ row.remark === '' ? '暂无信息' : row.remark }}</span>
        </template>
      </el-table-column>
     <el-table-column
          show-overflow-tooltip="true"
         label="旧拒绝-同意原因"
         width="160">
       <template v-slot="{row}">
         <span>{{ row.denyExplain }}</span>
       </template>
     </el-table-column> 
      <el-table-column prop="money" fixed="right" width="110" label="操作" >
        <template v-slot="{row}">
            <el-button size="small" style="width: 14px;" type="text" @click="particulars = row,particularsDialog = true,load(row)">详情</el-button>
            <el-button size="small" style="width: 14px;" type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog
        v-model="importDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="1"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div v-for="item in repetitionData" :key="item">
        {{item}}
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button type="primary" :loading="importRefundLoading" @click="importRefund">开始导入</el-button>
      </div>
    </el-dialog>
  <el-dialog v-model="editMoney">
     <div class="oldPeo">
      <div>
       修改前退费金额为：<el-input v-model="Moneys" disabled></el-input>
      </div>
     </div>
     <div class="newPeo">
       修改后退费金额为：<el-input v-model="editList.money"></el-input>
     </div>
     <template #footer>
          <span class="dialog-footer">
            <el-button @click="editMoney=false">取 消</el-button>
            <el-button type="primary" @click="submitUpdate()">确 定</el-button>
          </span>
        </template>
  </el-dialog>


  <el-dialog v-model="particularsDialog" title="退费详情" width="1200px">
    <returnDetail :particulars="particulars"/>
  </el-dialog>
  </div>
</template>

<script>
import {getRefund,getReserve,returnExcel,edit} from "@/api/refundOrTurnRecord";
import {selectOrderTurnInfoById, financeSend,importRefund} from "@/api/orders";
import {selectGetRecordById} from "@/api/equipment";
import {selectAreaByExpress} from "@/api/area";
import selectArea from "../../../components/SelectArea";
import SelectArea from "../../../components/SelectArea";
import returnDetail from "./components/ReturnDetail";
export default {
  components: {SelectArea,returnDetail},
  props:{
    selectArea
  },
  name: "Return",
  data() {
    return {
      tableListTwo:[],
      editMoney:false,
      particulars:null,
      particularsDialog:false,
      data: [],
      importDialog:false,
      importRefundLoading:false,
      tableLoading: false,
      regionList: [],
      campusList: [],
      time: [],
      completeTime: [],
      pageTotal: 100,
      turnInfo: {},
      Upload: false,
      Moneys:'',
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [],
      query: {
        areaList:[],
        status: null,
        studentName: '',
        coachName: '',
        startDate: null,
        endDate: null,
        completeStartDate: null,
        completeEndDate: null,
        type:null,
        pageIndex: 1,
        pageSize: 10,
      },
     editList:{
      refundId:'',
      money:''
     }
    }
  },
  created() {
    // this.getData();
    // 城市下拉数据
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
    this.getData()
  },
  methods: {
    //确定修改
    submitUpdate(){
      edit(this.editList).then(res=>{ 
       if(res.code==200){ 
        this.$message.success("操作成功");
        this.editMoney=false
        this.getData()
        this.editList={
          refundId:'',
          money:''
        }
       }else{
        this.$message.error("操作失败");
       }
      })
    },
      //修改金额
      handleEdit(row){ 
       this.editMoney = true
       this.editList.refundId = row.refundId
       this.Moneys = row.money
      },
      getAreaListId(val){  
        this.query.areaList = val; 
      },
      exceedFile(files, fileList) {
        this.$message.warning(`只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
      },
      handleRemove() {
        this.fileList = []
      },
      // 文件状态改变时的钩子
      fileChange(file) {
        let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        let size = file.size / 1024 / 1024;
        if (extension !== "xlsx") {
          this.fileList = [];
          this.$message.warning("只能上传后缀是.xlsx的文件");
        }
        if (size > 10) {
          this.fileList = [];
          this.$message.warning("文件大小不得超过10M");
        }
        if (extension == "xlsx" && size < 10) {
          this.sendEmailError = [];
          this.fileList.push(file.raw);
        }
      },
      importRefund() {
        this.importRefundLoading = true;
        if (this.fileList.length === 0) {
          this.$message.warning('请上传文件');
        } else {
          let form = new FormData();
          form.append("file", this.fileList[0]);
          importRefund(form).then(val => {
            this.importRefundLoading = false;
            if (val.code == 200) {
              this.repetitionData = val.data;
              this.$message.success("导入成功！");
            } else {
              this.$message.error("导入失败！");
            }
          })
        }
      },
      uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        // this.uploadState.Switch = true;
        financeSend(form).then(value => {
          this.Upload = false;
          let blob = new Blob([value], {
            type: "application/vnd.ms-excel"
          });
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a'); //我们用模拟q标签点击事件
          const fname = '订单统计信息'; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute('download', fname);
          document.body.appendChild(link);
          /**
           * 在这期间，我们可以做自己界面的反应逻辑
           **/
          link.click(); //点击
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(URL); // 释放掉blob对象
        })
      }

    },
    getData() {
      if (this.time.length !== 0) {
        this.query.startDate = this.dateFormat(this.time[0]);
        this.query.endDate = this.dateFormat(this.time[1]);
      }
      if (this.completeTime.length !== 0) {
        this.query.completeStartDate = this.dateFormat(this.completeTime[0]);
        this.query.completeEndDate = this.dateFormat(this.completeTime[1]);
      }
      let query = this.query;
      // if (this.query.areaList!=null && this.query.areaList!=""){
      //   query.areaList = JSON.stringify(this.query.areaList).replace('[','').replace(']','');
      // }
      getRefund(query).then(res => {
        if (res.code == 200) {
          this.data = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取数据失败");
        }
      })
    },
    exportExcel() {
      this.dialogVisible  = true
      returnExcel(this.query).then(res => {
          console.log(res);
      })
    },
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    load(tree) {
      let id = tree.refundId;
      let index = null;
      this.data.forEach(function(item,indexs){
        if(item.refundId==id){
            index =  indexs;
        }
      })
      let orderInfo = {order:{}};
      let turnInfo = {orders:{}}
      let earnestInfo = {earnestorder:{}}
       if(tree.type==1){
          selectOrderTurnInfoById(tree.orderId).then(val => {
            if (val.data.code == 200) {
                orderInfo.order = val.data.data.oldOrders;
                let a = this.data[index];
                let  order = Object.assign(a, orderInfo);
                this.data[index] = order;
            } else {
                this.$message.error("信息查询失败");
            }
         })    
       }else if(tree.type==2) {
        selectGetRecordById(tree.orderId).then(val => {
          if (val.code == 200) {
            turnInfo.orders= val.data;
            let a = this.data[index];
            let order = Object.assign(a, turnInfo);
            this.data[index] = order;
          } else {
            this.$message.error("信息查询失败");
          }
        }) 
       }else if(tree.type==3){ 
           getReserve(tree.orderId).then(val=>{ 
              if(val.code == 200){ 
                earnestInfo.earnestorder = val.data             
                let a = this.data[index]
                let order = Object.assign(a,earnestInfo)
                this.data[index] = order
                console.log(this.data[index]);
              }else{ 
                this.$message.error('信息查询失败')
              }
           })
       }
    },
    // 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses
      }
    },
    reset() {
      this.time = [],
          this.completeTime = [],
          this.query.regionId = null,
          this.query.campusId = null,
          this.query.status = null,
          this.query.student = null,
          this.query.coach = '',
          this.query.startDate = null,
          this.query.endDate = null,
          this.query.completeStartDate = null,
          this.query.completeEndDate = null,
          this.query.type=null,
          this.query.pageIndex = 1,
          this.query.pageSize = 10,
          this.getData()
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    }
  }
}
</script>
<style scoped>
.equipment {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px
}

.credentialsImg {
  width: 60px;
  height: 60px;
  border-radius: 12px
}

.mr10 {
  margin-right: 10px;
}

.handle-box {
  margin-bottom: 20px;
  display: flex;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.redItem {
  color: #99A9C5;
}

.card {
  width: 370px;
  margin: 0 80px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.student {
   
  text-align: left;
  padding: 6px 0;
  display: flex;
  justify-content: space-between;
}

.time {
  text-align: left;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sug_info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
.cell4{ 
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// div.el-step.is-horizontal.is-center.step{ 
//   min-width: 50px !important;
 
// }

</style>
<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>