import request from '../utils/request';

/**
 * 查询退费转卡记录
 * @param query
 * @returns {AxiosPromise}
 */
export const getRefundOrTurnRecord = (query) => {
    return request({
        url: '/sys/refundOrTurn/getRefundOrTurnRecord',
        method: 'get',
        params:query
    });
};

/**
 * 查询退费记录
 * @param query
 * @returns {AxiosPromise}
 */
export const getRefund = (query) => {
    return request({
        url: '/sys/refund/page',
        method: 'POST',
        dataType: "json",   
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 查询转卡记录
 * @param query
 * @returns {AxiosPromise}
 */
 export const getTurn = (query) => {
    return request({
        url: '/sys/orders/apportion/card/page', 
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 查询转卡新卡记录
 * @param orderAlterationCardId
 * @returns {AxiosPromise}
 */
 export const getTrunnewCard = (orderAlterationCardId) => {
    return request({
        url: '/sys/orders/apportion/card/selectDetails', ///api/sys/orders/apportion/card/selectDetails
        method: 'GET',
        params: {orderAlterationCardId}
    });
};

// /**
//  * 导出退费转卡记录
//  * @param query
//  * @returns {AxiosPromise}
//  */
// export const exportRefundAndTurnCardExcel = (query) => {
//     return request({
//         url: '/sys/refundOrTurn/exportRefundAndTurnCardExcel',
//         method: 'get',
//         params:query,
//         responseType: 'arraybuffer'
//     });
// };

//转卡导出
export const turnExcel = (query)=>{ 
    return request({
        url: '/sys/orders/apportion/card/excel', 
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
}

//退费导出
export const returnExcel = (query) => {
    return request({
        url: '/sys/refund/excel', ///api/sys/refund/excel
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};


//定金退费
export const getReserve = (reserveId) => {
    return request({
        url: '/sys/refund/getReserve',
        method: 'get',
        params:{reserveId},
        
    });
};

//金额更新
export const edit = (query) => {
    return request({
        url: '/sys/refund/edit',
        method: 'get',
        params:query
        
    });
};
